import { FC, ReactNode, useEffect } from 'react'
import { usePlausible } from 'next-plausible'
import { useRouter } from 'next/router'

interface PageTrackingProps {
  children: ReactNode
}

const PageTracking: FC<PageTrackingProps> = ({ children }) => {
  const { asPath } = useRouter()
  const plausible = usePlausible()

  useEffect(() => {
    plausible('pageview', { u: `${window.location.origin}${asPath}` })
  }, [asPath, plausible])

  return <>{children}</>
}

export default PageTracking

/**
 * One env config file to rule them all
 */
export const ENV_CONFIG = {
  // * Shared values between staging and production
  TURNSTILE_SITEVERIFY_URL: 'https://challenges.cloudflare.com/turnstile/v0/siteverify',
  TURNSTILE_SITE_KEY: '0x4AAAAAAACRwYjpnMIaYGPg',
  SENTRY_DSN: 'https://03dfe9eaa96046b8966ada7322058bb5@o1036217.ingest.sentry.io/6308416',
  NODE_ENV: process.env.NODE_ENV,
  //* Client env variables
  DIRECTUS_URL: process.env.NEXT_PUBLIC_DIRECTUS_URL,
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  //* Server env variables
  TURNSTILE_SECRET_KEY: process.env.TURNSTILE_SECRET_KEY,
} as const
